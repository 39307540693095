export const BASE_URL = "https://api.theechoapp.com/api/v1";

export const routes = {
  // -----AUTH------//
  signUp: BASE_URL + "/users/admin-register",
  signIn: BASE_URL + "/users/admin-login",
  deleteUser: BASE_URL + "/users/delete-user",
  getState: BASE_URL + "/users/stats",
  forgetPassword: BASE_URL + "/users/forgot-password",
  verifyOTP: BASE_URL + "/users/verify-otp",
  resendOTP: BASE_URL + "/users/resend-otp",
  resetPassword: BASE_URL + "/users/reset-password",
  // -------Expertise---------
  expertise: BASE_URL + "/expertise",
  createExpertise: BASE_URL + "/expertise/create-expertise",
  deleteExpertise: BASE_URL + "/expertise/delete-expertise",
  updateExpertise: BASE_URL + "/expertise/update-expertise",
  // -------Goals-----------
  getGoals: BASE_URL + "/goals",
  createGoal: BASE_URL + "/goals/create-goal",
  updateGoal: BASE_URL + "/goals/update-goal",
  deleteGoal: BASE_URL + "/goals/delete-goal",
  // -------Interest-----------
  getInterest: BASE_URL + "/interests",
  createInterest: BASE_URL + "/create-interest",
  updateInterest: BASE_URL + "/interests/update-interest",
  deleteInterest: BASE_URL + "/delete-interest",
  //-----------------------
  logOut: BASE_URL + "/users/logout",
  getAllUser: BASE_URL + "/users",
  // ============== Term and Condition=========
  createTerm: BASE_URL + "/terms/create-terms",
  getTerm: BASE_URL + "/terms",
  updateTerm: BASE_URL + "/terms/update-terms",
  deleteTerm: BASE_URL + "/terms",
  // ========= Privacy Policy =============
  createPrivacy: BASE_URL + "/policy/create-policy",
  getPrivacy: BASE_URL + "/policy",
  updatePrivacy: BASE_URL + "/policy/update-policy",
  //======About Us===============
  getAboutUs: BASE_URL + "/about",
  createAboutUs: BASE_URL + "/about/create-about",
  updateAboutUs: BASE_URL + "/about/update-about",
  userStats: BASE_URL + "/users/stats",
  getDispute: BASE_URL + "/dispute",
  blockUser: BASE_URL + "/users/block-user",

  // -----------Reviews--------------//
  getAllReviews: BASE_URL + "/reviews",
  deleteReview: BASE_URL + "/reviews/delete-review",
  createReview: BASE_URL + "/reviews/create-review",
  // -------------Main-Image-------------//
  getImage: BASE_URL + "/app-image",
  createImage: BASE_URL + "/app-image/create-app-image",
  updateImage: BASE_URL + "/app-image/update-app-image",
  // -------------Main-Image-------------//
  getOurMission: BASE_URL + "/our-mission",
  createOurMission: BASE_URL + "/our-mission/create-our-mission",
  updateOurMission: BASE_URL + "/our-mission/update-our-mission",
  // -------------Social-Links-------------//
  getSocialLinks: BASE_URL + "/social-media-links",
  createSocialLinks: BASE_URL + "/social-media-links/create-social-media-links",
  updateSocialLinks: BASE_URL + "/social-media-links/update-social-media-links",

  // -------------Services-------------//
};
