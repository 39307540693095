import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Table, Image, Button, theme } from "antd";

// import { Tag } from "react-tag-input/types/components/SingleTag";
import {
  addIcon,
  crossIcon,
  editIcon,
  history,
  homeIcon,
  redTrash,
  trueIcon,
} from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import "./style.scss";
import COUNTRIES from "./country";
import { GreenNotify } from "../../helper/helper";
import ExpertModal from "../../components/loader/addExpertModal/expertModal";

const Expertise = () => {
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [termID, setTermID] = useState("");
  const [expertise, setExpertise] = useState(null);
  const [tags, setTags] = useState([]);

  const [content, setContent] = useState("");

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getExpertise = () => {
    let getRes = (res) => {
      console.log("get res", res);
      setTags(res?.data?.docs);
    };

    callApi("GET", routes.expertise, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const deleteExpert = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      setUpdateApi(true);
      if (res.statusCode == 200) {
        GreenNotify("expert is deleted successful");
      }
    };

    callApi(
      "DELETE",
      `${routes.deleteExpertise}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getExpertise();
  }, [updateApi]);

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      className: "role-name-column-header",
    },

    {
      title: "Edit",
      dataIndex: "edit",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = tags?.map((item, index) => {
    return {
      key: index,
      firstName: item?.name,
      role: <p style={{ fontWeight: "bold" }}>kk</p>,

      edit: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setExpertise(item);
            toggleModal();
          }}
        >
          <img src={editIcon} alt="" />
        </div>
      ),
      delete: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => deleteExpert(item?._id)}
        >
          <img src={redTrash} alt="" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>Expertise</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1> {`Expertise`}</h1>
        <div className="configure-server-roles-main-heading-container">
          <div
            onClick={() => {
              toggleModal();
              setExpertise(null);
            }}
            className="server-roles-add-btn"
          >
            <img src={addIcon} alt="" />
            <p>Add New Expert</p>
          </div>
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
        ></Table>
      </div>

      {showModal && (
        <ExpertModal
          item={expertise}
          show={showModal}
          onClose={() => setShowModal(false)}
          setIsLoading={setIsLoading}
          setUpdateApi={setUpdateApi}
        />
      )}
    </div>
  );
};

export default Expertise;
