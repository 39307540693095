import React, { useState, useRef } from "react";
import "./style.css";
import { Button, Input, Typography, Rate } from "antd";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import { GreenNotify, RedNotify, uploadOnS3 } from "../../helper/helper";

const AddTestimonial = ({
  show,
  onClose,
  children,
  setIsLoading,
  setUpdateApi,
  item,
}) => {
  const [value, setValue] = useState("");
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [rating, setRating] = useState(0);
  const [image, setImage] = useState("");
  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  //   if (!show) {
  //     return null;
  //   }

  const fileInputImageRef = useRef(null);

  const createTestimonial = () => {
    setUpdateApi(true);

    let getRes = (res) => {
      if (res.statusCode === 201) {
        GreenNotify("Testimonial created successfully.");
      }
      setUpdateApi(false);
      onClose();
    };
    let body = {
      title: title,
      body: value,
      fullName: author,
      avatar: image,
      rating: rating * 1,
    };

    callApi(
      "POST",
      routes.createReview,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const updateExpert = () => {
    setUpdateApi(true);

    let getRes = (res) => {
      if (res.statusCode === 201) {
        GreenNotify("Testimonial created successfully.");
      }
      setUpdateApi(false);
      onClose();
    };
    let body = { name: value };

    callApi(
      "PATCH",
      `${routes.updateInterest}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const pickImageFile = () => {
    fileInputImageRef.current.click();
  };
  const disabled =
    value.length !== 0 &&
    author.length !== 0 &&
    title.length !== 0 &&
    image.length !== 0 &&
    rating !== 0;
  console.log("rating", rating);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add new expertise.</h2>

        <Typography.Title level={5} style={{ marginTop: "2rem" }}>
          Name
        </Typography.Title>
        <Input
          style={{ marginTop: "1rem", height: "5rem" }}
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Name"
        />
        <Typography.Title level={5} style={{ marginTop: "2rem" }}>
          Title
        </Typography.Title>
        <Input
          style={{ marginTop: "1rem", height: "5rem" }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
        <Typography.Title level={5} style={{ marginTop: "2rem" }}>
          Description
        </Typography.Title>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ marginTop: "1rem", height: "5rem" }}
          placeholder="Description "
        />
        <Typography.Title level={5} style={{ marginTop: "2rem" }}>
          Upload Image
        </Typography.Title>

        <div className="image-upload-container">
          {/* <h1> Add Image</h1> */}
          <img
            onClick={pickImageFile}
            alt="image.jpeg"
            src={image.length !== 0 ? image : dummyImage}
          />
          <input
            type="file"
            ref={fileInputImageRef}
            style={{ display: "none" }}
            onChange={uploadOnS3((url) => setImage(url), setIsLoading)}
            accept="image/*"
          />
        </div>
        <Typography.Title level={5} style={{ marginTop: "2rem" }}>
          Rating
        </Typography.Title>
        <Rate
          style={{ fontSize: 26 }}
          value={rating}
          onChange={(val) => setRating(val)}
        />

        <div className="add-expert-modal-btn-container">
          <Button
            disabled={!disabled}
            onClick={item ? updateExpert : createTestimonial}
            type="primary"
          >
            {item ? "Update" : "Create"}
          </Button>
          <div style={{ marginLeft: "2rem" }}>
            <Button onClick={onClose} type="primary" danger>
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonial;
