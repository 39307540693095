import React from "react";
import { Layout, Menu } from "antd";
import { homeIcon, logOutIcon, userIcon } from "../assets";
import "./layout.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accessToken, refreshToken, userData } from "../redux/userDataSlice";
import { callApi } from "../api/apiCaller";
import { routes } from "../api/routes";
import { useState } from "react";
import Loader from "../components/loader/loader";
import { GreenNotify, RedNotify } from "../helper/helper";
import Dashboard from "../pages/dashboard/dashboard";
import Home from "../pages/home/home";
import Users from "../pages/userlist/Users";
import {
  aboutIcon,
  contentIcon,
  expert,
  goals,
  homeIconSvg,
  interest,
  privcyIcon,
  termIcon,
  testimonialIcon,
} from "./icon";
import Term from "../pages/term/term";
import Privacy from "../pages/privcy/privcy";
import AboutUS from "../pages/aboutUs/aboutUs";
import Expertise from "../pages/userExperties/expertise";
import Goals from "../pages/userGoals/userGoals";
import Interest from "../pages/userInterest/userInterest";
import Testimonial from "../pages/testimonials/testimonials";
import WContent from "../pages/webContent/content";

const { Content, Sider } = Layout;
const LayoutDashboard = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    let getRes = (res) => {
      if (res.status === 200) {
        GreenNotify(res.message);
        dispatch(userData(null));
        dispatch(accessToken(""));
        dispatch(refreshToken(""));
      } else {
        RedNotify(res.message);
      }
    };

    let body = {
      device: {
        id: localStorage.getItem("deviceId"),
        deviceToken: "xyz",
      },
    };

    callApi("POST", routes.logOut, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Loader loading={isloading} />
      <Sider style={{ background: "#1D191C" }} width={280}>
        <div
          onClick={() => navigate("/")}
          style={{
            paddingTop: "4rem",
            textAlign: "center",
            color: "white",
            fontSize: "2.3rem",
            cursor: "pointer",
          }}
        >
          <h2>Echo Admin Panel</h2>
        </div>
        <Menu
          style={{
            marginTop: "7rem",
            fontFamily: "sans-serif",
            fontSize: "1.8rem",
            fontWeight: "700",
            color: "#fff",
            backgroundColor: "#1D191C",
          }}
          inlineCollapsed={true}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
        >
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/")}
            icon={homeIconSvg}
            key="92"
          >
            Home
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/users")}
            icon={<img className="icon" src={userIcon} alt="" />}
            key="95"
          >
            Users
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/web-content")}
            icon={contentIcon}
            key="25"
          >
            Web Content
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/testimonial")}
            icon={testimonialIcon}
            key="72"
          >
            Testimonials
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/expertise")}
            icon={expert}
            key="84"
          >
            Expertise
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/goals")}
            icon={goals}
            key="83"
          >
            Goals
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/interest")}
            icon={interest}
            key="82"
          >
            Interest
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/privacy-and-policy")}
            icon={privcyIcon}
            key="94"
          >
            Privacy and policy
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/term-and-condition")}
            icon={termIcon}
            key="96"
          >
            Term and Condition
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/about-us")}
            icon={aboutIcon}
            key="98"
          >
            About Us
          </Menu.Item>
          <Menu.Item
            style={{ marginTop: "5rem" }}
            icon={<img className="log-out" src={logOutIcon} alt="" />}
            onClick={logOut}
            key="89"
          >
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            background: "#fff",
            paddingTop: "2rem",
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/users" element={<Users />}></Route>
            <Route path="/term-and-condition" element={<Term />}></Route>
            <Route path="/privacy-and-policy" element={<Privacy />}></Route>
            <Route path="/about-us" element={<AboutUS />}></Route>
            <Route path="/expertise" element={<Expertise />}></Route>
            <Route path="/goals" element={<Goals />}></Route>
            <Route path="/interest" element={<Interest />}></Route>
            <Route path="/testimonial" element={<Testimonial />}></Route>
            <Route path="/web-content" element={<WContent />}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;
