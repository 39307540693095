import React, { useState } from "react";

import { Form, Input, Button, Checkbox } from "antd";
import { DeviceUUID } from "device-uuid";
import { callApi } from "../../../api/apiCaller";
import { useDispatch } from "react-redux";
import {
  accessToken,
  refreshToken,
  userData,
} from "../../../redux/userDataSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../api/routes";
import Loader from "../../../components/loader/loader";
import { GreenNotify, RedNotify } from "../../../helper/helper";
import { logo } from "../../../assets";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      let id = new DeviceUUID().get();
      localStorage.setItem("deviceId", id);
      deviceId = id;
    }

    let getRes = (res) => {
      console.log("res of sign up", res);
      if (res.statusCode === 200 || res.statusCode == 201) {
        dispatch(userData(res?.data?.user));
        dispatch(accessToken(res?.token));
        dispatch(refreshToken(res?.refreshToken));
        GreenNotify("signUp Successfully");
        navigate("/", { replace: true });
      } else {
        RedNotify(res.message);
      }
    };
    let body = {
      email: values.email,
      password: values.password,
      // deviceId: deviceId,
      // fcmToken: "ang",
    };
    callApi("POST", routes.signUp, body, setIsLoading, getRes, (error) => {
      console.log("eror", error);
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="admin-panel-login">
      <div className="login-main-container">
        <Loader loading={isloading} />
        <div className="auth-logo-container">
          <img
            src={logo}
            alt="logo"
            style={{ marginBottom: "1rem", width: "20rem", height: "20rem" }}
          />
        </div>
        <h1 style={{ marginBottom: "3rem", fontFamily: "sans-serif" }}>
          Sign Up
        </h1>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={
              <p
                style={{
                  fontSize: "1.8rem",
                  fontFamily: "sans-serif",
                }}
              >
                Email
              </p>
            }
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <p style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
                Password
              </p>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <div
              onClick={() => navigate("/login")}
              className="sign-up-container"
            >
              <h4>Login</h4>
            </div>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
