import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import LayoutDashboard from "../layout/layout";
import Login from "../pages/Auth/login/login";
import PrivateRoute from "./private-route";
import SignUp from "../pages/Auth/signUp/signUp";
import ForgetPassword from "../pages/Auth/forgetPassword/forgetPassword";
import OTPVerify from "../pages/Auth/otpVerify/otpVerify";
import UpdatePassword from "../pages/Auth/updatePassword/updatePassword";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/signUp" element={<SignUp />}></Route>
      <Route path="/forgetPassword" element={<ForgetPassword />}></Route>
      <Route path="/otpVerify" element={<OTPVerify />}></Route>
      <Route path="/updatePassword" element={<UpdatePassword />}></Route>
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <LayoutDashboard />
          </PrivateRoute>
        }
      >
        {/* <Route path="/dashboard" element={<Pages.Dashboard />} /> */}
      </Route>
    </Routes>
  );
};

export default Navigation;
