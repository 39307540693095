import { toast } from "react-toastify";
import AWS from "aws-sdk";
var Buffer = require("buffer/").Buffer;
export const GreenNotify = (text) => {
  toast.success(text, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const RedNotify = (text) => {
  toast.error(text, {
    position: toast.POSITION.TOP_CENTER,
  });
};

AWS.config.update({
  accessKeyId: "AKIA3FLDYE3EDGM6EQUB",
  secretAccessKey: "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",
  region: "us-east-2",
});

const S3 = new AWS.S3();
export const uploadOnS3 = (cb, loader) => (evt) => {
  const files = evt.target.files;
  const file = files[0];
  loader(true);
  uploadImageOnS3(file)
    .then((url) => {
      loader(false);
      cb(url);
      // console.log("url", url);
    })
    .catch((error) => {
      console.log("error", error);
      loader(false);
    });
};

const uploadImageOnS3 = async (src) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const params = {
          Bucket: "s3echobucket",
          Key: `${10000 + Math.round(Math.random() * 10000)}.jpeg`,
          Body: new Buffer(
            reader.result.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          ),
        };
        let res = await S3.upload(params).promise();
        console.log(res);
        return resolve(res.Location);
      };
      reader.onerror = (e) => console.log("OOPS!", e);
      reader.readAsDataURL(src);
    } catch (error) {
      console.error("Error uploading to S3:", error);
      reject(error);
    }
  });
};

export const upload = (cb, setIsLoading) => (evt) => {
  const files = evt.target.files;
  const file = files[0];

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MmU1YWQ5Y2ZjN2JlZjE3ZjFkOTkxNCIsImlhdCI6MTY4MTEyMDU0NCwiZXhwIjoxNjg4ODk2NTQ0fQ.MwVbniYhtKpSyleEJwCJ_z6GKP9wlg4JEszWOIbOTsU"
  );

  var formdata = new FormData();
  formdata.append("file", file);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  setIsLoading(true);
  fetch(
    "https://rxje2xzpme.us-east-1.awsapprunner.com/api/v1/user/upload",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      const url = data.url;
      setIsLoading(false);
      cb(url);
    })
    .catch((error) => {
      setIsLoading(false);
      console.log("error", error);
    });
};
