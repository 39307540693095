import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Button, Typography, Row, Col, Input, Form } from "antd";

// import { Tag } from "react-tag-input/types/components/SingleTag";
import { homeIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import { GreenNotify, uploadOnS3 } from "../../helper/helper";
import ExpertModal from "../../components/loader/addExpertModal/expertModal";

const WContent = () => {
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [image, setImage] = useState("");
  const [socialID, setSocialID] = useState("");
  const [image2, setImage2] = useState("");
  const [imageId, setImageId] = useState("");
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [missionId, setMissionID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [furl, setFUrl] = useState("");
  const [Iurl, setIUrl] = useState("");
  const [Turl, setTUrl] = useState("");
  const [lurl, setLUrl] = useState("");
  const fileInputImageRef = useRef(null);
  const fileInputImageRef2 = useRef(null);
  const [form] = Form.useForm();
  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  const validateUrl = (rule, value) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))" + // domain name or IP address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (value && !urlPattern.test(value)) {
      return Promise.reject("Please enter a valid URL");
    }
    return Promise.resolve();
  };

  const onFinish = (values) => {
    let getRes = (res) => {
      console.log("res of create social links", res);
    };
    let body = {
      twitter: Turl,
      instagram: Iurl,
      linkedin: lurl,
      facebook: furl,
    };

    callApi(
      "PATCH",
      `${routes.updateSocialLinks}/${socialID}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const uploadImage = () => {
    let getRes = (res) => {};
    let body = { image: image };
    callApi(
      "PATCH",
      `${routes.updateImage}/${imageId}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const updateOurMission = () => {
    let getRes = (res) => {
      console.log("res", res);
    };
    let body = { image: image2, heading: title, content: heading };
    callApi(
      "PATCH",
      `${routes.updateOurMission}/${missionId}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const getImage = () => {
    let getRes = (res) => {
      setImage(res?.appImage?.image);
      setImageId(res?.appImage?._id);
    };
    let body = { image: image };
    callApi("GET", routes.getImage, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const getSocialMedia = () => {
    let getRes = (res) => {
      console.log("res", res);
      setSocialID(res?.socialMediaLinks?._id);
      setFUrl(res?.socialMediaLinks?.facebook);
      setTUrl(res?.socialMediaLinks?.twitter);
      setIUrl(res?.socialMediaLinks?.instagram);
      setLUrl(res?.socialMediaLinks?.linkedin);
    };
    let body = { image: image };
    callApi(
      "GET",
      routes.getSocialLinks,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const getOurMission = () => {
    let getRes = (res) => {
      setTitle(res?.ourMission?.heading);
      setMissionID(res?.ourMission?._id);
      setImage2(res?.ourMission?.image);
      setHeading(res?.ourMission?.content);
    };

    callApi(
      "GET",
      routes.getOurMission,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const pickImageFile = () => {
    fileInputImageRef.current.click();
  };
  const pickImageFile2 = () => {
    fileInputImageRef2.current.click();
  };
  useEffect(() => {
    getImage();
    getOurMission();
    getSocialMedia();
  }, [updateApi]);

  return (
    console.log("id", socialID, furl),
    (
      <div className="admin-products-main-container">
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Web Content</Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1> {`Web Content`}</h1>
        </div>

        <Row>
          <Col span={12}>
            <Typography.Title level={4} style={{ marginTop: "2rem" }}>
              Our Mission
            </Typography.Title>

            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              heading
            </Typography.Title>
            <Input
              style={{ marginTop: "1rem", height: "5rem", width: "35rem" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="heading"
            />
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Content
            </Typography.Title>
            <Input
              style={{ marginTop: "1rem", height: "5rem", width: "35rem" }}
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="content"
            />
            <div className="image-upload-container">
              {/* <h1> Add Image</h1> */}
              <img onClick={pickImageFile2} alt="image.jpeg" src={image2} />
              <input
                type="file"
                ref={fileInputImageRef2}
                style={{ display: "none" }}
                onChange={uploadOnS3((url) => setImage2(url), setIsLoading)}
                accept="image/*"
              />
            </div>
            <Button
              style={{ marginTop: "2rem" }}
              // disabled={!disabled}
              onClick={updateOurMission}
              type="primary"
            >
              {"Update Mission"}
            </Button>
          </Col>
          <Col span={12}>
            <Typography.Title level={4} style={{ marginTop: "2rem" }}>
              Main image
            </Typography.Title>
            <div className="image-upload-container">
              {/* <h1> Add Image</h1> */}
              <img
                onClick={pickImageFile}
                alt="image.jpeg"
                src={image.length !== 0 ? image : dummyImage}
              />
              <input
                type="file"
                ref={fileInputImageRef}
                style={{ display: "none" }}
                onChange={uploadOnS3((url) => setImage(url), setIsLoading)}
                accept="image/*"
              />
            </div>
            <Button
              style={{ marginTop: "2rem" }}
              // disabled={!disabled}
              onClick={uploadImage}
              type="primary"
            >
              {"Update Image"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography.Title level={4} style={{ marginTop: "2rem" }}>
              Social Links
            </Typography.Title>

            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Facebook
            </Typography.Title>
            <Input
              placeholder="https://example.com"
              value={furl}
              onChange={(e) => setFUrl(e.target.value)}
            />
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Instagram
            </Typography.Title>
            <Input
              placeholder="https://example.com"
              value={Iurl}
              onChange={(e) => setIUrl(e.target.value)}
            />
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Twitter
            </Typography.Title>
            <Input
              placeholder="https://example.com"
              value={Turl}
              onChange={(e) => setTUrl(e.target.value)}
            />
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              LInkedin
            </Typography.Title>
            <Input
              placeholder="https://example.com"
              value={lurl}
              onChange={(e) => setLUrl(e.target.value)}
            />

            <Button
              style={{ marginTop: "2rem" }}
              type="primary"
              onClick={onFinish}
            >
              Update
            </Button>
          </Col>
          <Col span={12}></Col>
        </Row>
      </div>
    )
  );
};

export default WContent;
