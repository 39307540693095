import React, { useState } from "react";
import "./modal.css";
import { Button, Input } from "antd";
import { callApi } from "../../../api/apiCaller";
import { routes } from "../../../api/routes";
import { RedNotify } from "../../../helper/helper";
const ExpertModal = ({
  show,
  onClose,
  children,
  setIsLoading,
  setUpdateApi,
  item,
}) => {
  const [value, setValue] = useState(item ? item?.name : "");
  //   if (!show) {
  //     return null;
  //   }

  const createExp = () => {
    setUpdateApi(true);
    if (value.length == 0) return RedNotify("Need to enter expert");
    let getRes = (res) => {
      setUpdateApi(false);
      onClose();
    };
    let body = { name: value };

    callApi(
      "POST",
      routes.createExpertise,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const updateExpert = () => {
    setUpdateApi(true);
    if (value.length == 0) return RedNotify("Need to enter expert");
    let getRes = (res) => {
      setUpdateApi(false);
      onClose();
    };
    let body = { name: value };

    callApi(
      "PATCH",
      `${routes.updateExpertise}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add new expertise.</h2>

        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ maxWidth: "80%", marginTop: "3rem" }}
          placeholder="Title "
        />

        <div className="add-expert-modal-btn-container">
          <Button onClick={item ? updateExpert : createExp} type="primary">
            {item ? "Update" : "Create"}
          </Button>
          <div style={{ marginLeft: "2rem" }}>
            <Button onClick={onClose} type="primary" danger>
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertModal;
