import React, { useState } from "react";

import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { InputOTP } from "antd-input-otp";
import { DeviceUUID } from "device-uuid";
import { callApi } from "../../../api/apiCaller";
import { useDispatch } from "react-redux";
import {
  accessToken,
  refreshToken,
  userData,
} from "../../../redux/userDataSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../api/routes";
import Loader from "../../../components/loader/loader";
import { GreenNotify, RedNotify } from "../../../helper/helper";
import { logo } from "../../../assets";

const OTPVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isloading, setIsLoading] = useState(false);
  const [value, setValue] = useState([]);

  const onFinish = (values) => {
    const combineString = value.join("");
    let getRes = (res) => {
      console.log("res of OtpVerify", res);
      if (res.statusCode === 200) {
        navigate("/updatePassword", {
          state: { email: location?.state?.email },
        });
      } else {
        RedNotify(res.message);
      }
    };
    let body = {
      email: location?.state.email,
      otp: combineString,
      deviceId: "device1",
      fcmToken: "fcm1",
    };
    callApi(
      "POST",
      routes.forgetPassword,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("eror", error);
      }
    );
  };
  const resendOTP = (values) => {
    let getRes = (res) => {
      //   console.log("res of OtpVerify", res);
      if (res.statusCode === 200) {
        GreenNotify(`OTP resend to ${values.email}  Successfully`);
      } else {
        RedNotify(res.message);
      }
    };
    let body = {
      email: location?.state.email,
    };
    callApi("POST", routes.resendOTP, body, setIsLoading, getRes, (error) => {
      console.log("eror", error);
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    // console.log("value", value),
    <div className="admin-panel-login">
      <div className="login-main-container">
        <Loader loading={isloading} />
        <div className="auth-logo-container">
          <img
            src={logo}
            alt="logo"
            style={{ marginBottom: "1rem", width: "20rem", height: "20rem" }}
          />
        </div>
        <h1 style={{ marginBottom: "3rem", fontFamily: "sans-serif" }}>
          Verify OTP
        </h1>
        <Form
          name="basic"
          //   labelCol={{
          //     span: 8,
          //   }}
          //   wrapperCol={{
          //     span: 16,
          //   }}
          //   initialValues={{
          //     remember: true,
          //   }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: 400 }}
        >
          <Form.Item name="otp">
            <InputOTP
              value={value}
              onChange={(val) => setValue(val)}
              length={4}
              inputType="numeric"
            />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Row>
              <Col span={12}>
                <div onClick={resendOTP} className="sign-up-container">
                  <h4>Resend OTP</h4>
                </div>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 16,
            }}
          >
            <Button
              disabled={value.length < 4}
              type="primary"
              onClick={onFinish}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default OTPVerify;
